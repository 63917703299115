import React from 'react';
import './WebDevelopmentServicesDetails.css'; 
import { MdCheck } from "react-icons/md";
import ServiceImage from "../img/service-page-about.jpg"
import laef from "../img/leaf.png"
import { HiArrowSmallRight } from "react-icons/hi2";
import Plan from '../components/Plan/Plan';
import Choose from '../components/Choose/Choose';
import Faq from '../components/Faq/Faq';

const AppDevelopmentServicesDetails = () => {

    const faqQuestions = [
        {
          question: "What platforms do you develop apps for?",
          answer: "We specialize in developing apps for both native platforms like iOS and Android, as well as cross-platform solutions using frameworks like React Native."
        },
        {
          question: "How do you ensure the security of the developed apps?",
          answer: "We follow industry-standard security practices and conduct thorough testing to identify and fix any security vulnerabilities in the apps we develop."
        },
        {
          question: "Can you assist with app deployment to app stores?",
          answer: "Yes, we provide assistance with app deployment to various app stores, including the Apple App Store and Google Play Store."
        }
        // Add more questions as needed
      ];

    return (
        <div className="services-details-container">
            <div className="services-details-content">
               <div className='services-details-header'>
                    <span>App Development</span>
                    <img src={laef} alt="leaf" />
               </div>
               <div className='services-details-main-content'>
                    <div className='services-details-main-content-image'>
                        <img src={ServiceImage} alt="Service About" />
                    </div>
                    <div className='services-details-main-content-box'>
                        <div>
                            <span> <HiArrowSmallRight />What We Do</span>
                            <h1>Special Solutions For App Development</h1>
                        </div>
                        <div>
                            <p>Transform your ideas into innovative mobile applications with our expert app development services. We provide customized solutions to meet your unique business needs.</p>
                            <ul>
                                <li><span><MdCheck /></span>Native and Cross-Platform App Development</li>
                                <li><span><MdCheck /></span>UI/UX Design and Prototyping</li>
                                <li><span><MdCheck /></span>App Testing and Quality Assurance</li>
                            </ul>
                        </div>
                    </div>
               </div>

                 <Plan/>
                 <Choose/>
                 <Faq faqQuestions={faqQuestions}/>
               
            </div>
        </div>
    );
};

export default AppDevelopmentServicesDetails;
