import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WebDevelopmentServicesDetails from "./ServicesPage/WebDevelopmentServicesDetails";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import About from "./page/About/About";
import Homepage from "./page/Homepage/Homepage";
import Services from "./page/Services/Services";
import AppDevelopmentServicesDetails from './ServicesPage/AppDevelopmentServicesDetails';
import DigitalMarketing from './ServicesPage/DigitalMarketing';
import ErpSoftware from './ServicesPage/ErpSoftware';
import GSTBillSoftware from './ServicesPage/GSTBillSoftware';
import HotelManagementSoftware from './ServicesPage/HotelManagementSoftware';
import Quota from './components/Quota/Quota';

function App() {
  return (
    <BrowserRouter>
    <Navbar />
      <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/web-development" element={<WebDevelopmentServicesDetails />} />
          <Route path="/app-development" element={<AppDevelopmentServicesDetails />} />
          <Route path="/degital-marketing" element={<DigitalMarketing />} />
          <Route path="/erp-software" element={<ErpSoftware />} />
          <Route path="/gst-bill" element={<GSTBillSoftware />} />
          <Route path="/hotel-management" element={<HotelManagementSoftware />} />
          <Route path="/quota" element={<Quota />} />
          
      </Routes>
    <Footer />
    </BrowserRouter>
  );
}

export default App;
