import React from 'react';
import './WebDevelopmentServicesDetails.css'; 
import { MdCheck } from "react-icons/md";
import ServiceImage from "../img/service-page-about.jpg"
import laef from "../img/leaf.png"
import { HiArrowSmallRight } from "react-icons/hi2";
import Plan from '../components/Plan/Plan';
import Choose from '../components/Choose/Choose';
import Faq from '../components/Faq/Faq';

const ErpSoftware = () => {

    const faqQuestions = [
        {
          question: "What is ERP software?",
          answer: "ERP (Enterprise Resource Planning) software is a suite of integrated applications used by businesses to manage and automate various back-office functions such as accounting, human resources, inventory management, supply chain management, and more."
        },
        {
          question: "Why is ERP software important?",
          answer: "ERP software streamlines business processes, improves efficiency, enhances decision-making, reduces operational costs, and provides real-time insights into business performance."
        },
        {
          question: "What ERP software solutions do you offer?",
          answer: "We offer a range of ERP software solutions tailored to meet the specific needs of businesses across various industries. Our solutions include modules for finance, human resources, inventory management, sales, and more."
        }
        // Add more questions as needed
      ];

    return (
        <div className="services-details-container">
            <div className="services-details-content">
               <div className='services-details-header'>
                    <span>ERP Software</span>
                    <img src={laef} alt="leaf" />
               </div>
               <div className='services-details-main-content'>
                    <div className='services-details-main-content-image'>
                        <img src={ServiceImage} alt="Service About" />
                    </div>
                    <div className='services-details-main-content-box'>
                        <div>
                            <span> <HiArrowSmallRight />What We Do</span>
                            <h1>Comprehensive ERP Software Solutions</h1>
                        </div>
                        <div>
                            <p>Optimize your business operations and streamline workflows with our advanced ERP software solutions. From finance management to inventory control, our ERP systems are designed to empower your organization for success.</p>
                            <ul>
                                <li><span><MdCheck /></span>Finance Management</li>
                                <li><span><MdCheck /></span>Human Resources Management</li>
                                <li><span><MdCheck /></span>Inventory Management</li>
                                <li><span><MdCheck /></span>Supply Chain Management</li>
                                <li><span><MdCheck /></span>Sales and Customer Relationship Management (CRM)</li>
                                {/* Add more modules as needed */}
                            </ul>
                        </div>
                    </div>
               </div>

                 <Plan/>
                 <Choose/>
                 <Faq faqQuestions={faqQuestions}/>
               
            </div>
        </div>
    );
};

export default ErpSoftware;
