// About.js

import React from 'react';
import './About.css'; // Import your CSS file for styling

const About = () => {
    return (
        <div className="about-container">
            <div className="about-content">
                <h1>About Our IT Services Agency</h1>
                <p>
                    Welcome to our IT services agency! We are dedicated to providing top-notch IT solutions tailored to meet the unique needs of our clients. With a team of skilled professionals, we strive to deliver innovative and reliable services that drive business growth and success.
                </p>
                <p>
                    At our agency, we understand the importance of technology in today's business landscape. Whether you're a small startup or a large enterprise, we have the expertise and resources to support your IT needs. From software development to network security, we offer a comprehensive range of services to help your business thrive in the digital age.
                </p>
                <h2>Our Mission</h2>
                <p>
                    Our mission is to empower businesses with cutting-edge technology solutions that enhance efficiency, productivity, and competitiveness. We are committed to delivering exceptional value to our clients through personalized service, technical expertise, and continuous innovation.
                </p>
                <h2>Why Choose Us?</h2>
                <ul>
                    <li>Experienced Team: Our team consists of skilled professionals with years of experience in the IT industry.</li>
                    <li>Custom Solutions: We understand that every business is unique, so we offer customized solutions tailored to your specific requirements.</li>
                    <li>Customer Satisfaction: Customer satisfaction is our top priority. We go above and beyond to ensure that our clients are satisfied with our services.</li>
                    <li>Reliable Support: We provide reliable support and maintenance services to ensure that your systems run smoothly at all times.</li>
                </ul>
                <p>
                    Whether you need assistance with software development, cloud computing, cybersecurity, or IT consulting, you can count on us to deliver results that exceed your expectations. Contact us today to learn more about how our IT services agency can help your business succeed.
                </p>
            </div>
        </div>
    );
};

export default About;
