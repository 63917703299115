import React, { useState, useEffect } from 'react';
import './Testimonial.css'; // Import CSS for styling
import AboutImageShape from '../img/about-image-shape.png'; // Import your image

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Dummy data for testimonials
  const testimonials = [
    {
      name: 'Gaurav (USA)',
      position: 'Senior Developer',
      message: 'I am so impressed with the work that DreamViewer Infotech Company did for me. Their team was responsive, professional, and knowledgeable. They went above and beyond to deliver the results that exceeded my expectations and my requirements. I would like to highly recommend them to anyone in need of their services'
    },
    {
      name: 'Ravi',
      position: 'Senior Developer',
      message: 'I recently worked with DreamViewer Infotech Company & was blown away by their level of expertise & professionalism. Their team was incredibly responsive and attentive, and they cared about delivering exceptional results. I would highly recommend them to anyone looking for top-notch service and support.',
    },
    {
      name: 'Sanjeevani Gondane',
      position: 'Senior Developer',
      message: 'I cannot recommend DreamViewer Company highly enough! Their team is incredibly knowledgeable, responsive, and dedicated to providing outstanding service. I was thoroughly impressed with their professionalism and the quality of their work. Thank you, DreamViewer Infotech Company!',
    },
    {
      name: 'Jane Smith',
      position: 'Senior Developer',
      message: "I recently had the pleasure of working with DreamViewer Infotech Company, & I have to say, they exceeded my expectations in all way possible. From beg to end, Team was incredibly knowledgeable, and attentive to my needs. They were able to provide creative solutions to problems I didn't even know existed.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Auto slide every 5 seconds

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div className="testimonial">
      <div className="testimonial-container">
        <div className="left-content">
          <div className="image-border-shape">
            <img src={AboutImageShape} alt="about-image-shape" />
            <div className="bottom-border"></div>
          </div>
        </div>
        <div className="testimonial-content">
          <p className="testimonial-message">
            {testimonials[currentIndex].message}
          </p>
          <div className='auther-details'>
            <div className='author-profile'>
              <div>
                <h3>{testimonials[currentIndex].name}</h3>
                <span>{testimonials[currentIndex].position}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
