import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa"; // Import FaTimes for the cross icon
import "./Navbar.css";
import Logo from "../../img/Logo.png";
import CompanyPDF from "../../PDF/CompanyOverviewUpdated.pdf";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };

  }, []);

  useEffect(() => {
    // Close the menu when route changes
    setIsOpen(false);

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <nav className={isScrolled ? "scrolled" : ""}>
      <div className="logo">
        <img src={Logo} alt="" />
      </div>

      <div className={`section ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <a href={CompanyPDF} target="_blank" rel="noopener noreferrer">Portfolio</a>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <button><Link to={"/quota"}>Get A Quota</Link></button>
          </li>
        </ul>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />} 
      </div>
    </nav>
  );
};

export default Navbar;
