import React, { useState } from 'react';
import './Quota.css';

const Quota = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        company: '',
        serviceRequired: '',
        additionalInfo: '',
        attachment: null // For storing the uploaded file
      });

      const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        // Check if the input type is file
        const newValue = type === 'file' ? files[0] : value;
        setFormData({
          ...formData,
          [name]: newValue
        });
      };

 

      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log(formData);
        // Reset form fields after submission
        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          company: '',
          serviceRequired: '',
          additionalInfo: '',
          attachment: null
        });
      };
    

  return (
    <div className='quota'>
      <div className="quota-container">
        <h2>Request a Quotation</h2>
        <form className="quota-form" onSubmit={handleSubmit}>
        <div className="quota-form-conatiner">
            <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                />
            </div>
            <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                />
            </div>
            <div className="form-group">
                <label htmlFor="company">Company:</label>
                <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
                />
            </div>
            <div className="form-group">
                <label htmlFor="serviceRequired">Service Required:</label>
                <input
                type="text"
                id="serviceRequired"
                name="serviceRequired"
                value={formData.serviceRequired}
                onChange={handleChange}
                required
                />
            </div>
            <div className="form-group">
                <label htmlFor="attachment">Attachment:</label>
                <input
                type="file"
                id="attachment"
                name="attachment"
                onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="additionalInfo">Additional Information:</label>
                <textarea
                id="additionalInfo"
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
                rows="4"
                ></textarea>
            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Quota;
