import React from 'react';
import './WebDevelopmentServicesDetails.css'; 
import { MdCheck } from "react-icons/md";
import ServiceImage from "../img/service-page-about.jpg"
import laef from "../img/leaf.png"
import { HiArrowSmallRight } from "react-icons/hi2";
import Plan from '../components/Plan/Plan';
import Choose from '../components/Choose/Choose';
import Faq from '../components/Faq/Faq';

const GSTBillSoftware = () => {

    const faqQuestions = [
        {
          question: "What is GST Bill Software?",
          answer: "GST Bill Software is a digital tool designed to help businesses generate and manage invoices, track sales and purchases, calculate taxes, and comply with Goods and Services Tax (GST) regulations."
        },
        {
          question: "Why is GST Bill Software important?",
          answer: "GST Bill Software automates invoicing and tax calculations, reduces errors, ensures compliance with GST laws, and facilitates smooth business operations for GST-registered businesses."
        },
        {
          question: "What features does your GST Bill Software offer?",
          answer: "Our GST Bill Software includes features such as invoice generation, tax calculation, inventory management, client management, reporting, and GST return filing."
        }
        // Add more questions as needed
      ];

    return (
        <div className="services-details-container">
            <div className="services-details-content">
               <div className='services-details-header'>
                    <span>GST Bill Software</span>
                    <img src={laef} alt="leaf" />
               </div>
               <div className='services-details-main-content'>
                    <div className='services-details-main-content-image'>
                        <img src={ServiceImage} alt="Service About" />
                    </div>
                    <div className='services-details-main-content-box'>
                        <div>
                            <span> <HiArrowSmallRight />What We Offer</span>
                            <h1>Efficient GST Bill Software Solutions</h1>
                        </div>
                        <div>
                            <p>Streamline your billing process and ensure GST compliance with our comprehensive GST Bill Software. From invoice generation to tax filing, our software simplifies GST-related tasks for your business.</p>
                            <ul>
                                <li><span><MdCheck /></span>Invoice Generation</li>
                                <li><span><MdCheck /></span>Tax Calculation</li>
                                <li><span><MdCheck /></span>Inventory Management</li>
                                <li><span><MdCheck /></span>Client Management</li>
                                <li><span><MdCheck /></span>Reporting and Analytics</li>
                                {/* Add more features as needed */}
                            </ul>
                        </div>
                    </div>
               </div>

                 <Plan/>
                 <Choose/>
                 <Faq faqQuestions={faqQuestions}/>
               
            </div>
        </div>
    );
};

export default GSTBillSoftware;
