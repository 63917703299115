import React from 'react';
import { HiArrowSmallRight } from "react-icons/hi2";
import "./Plan.css";

const Plan = () => {
  return (
    <div className='plan'>
      <div className="plan-container">
        <div className="plan-header">
          <span><HiArrowSmallRight/>How We Work</span>
          <h2>Simple Steps to Complete Your Work</h2>
          <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum</p>
        </div>
        <div className="plan-details">
          <div className="plan-box">
            <div>
              <span>Step 01</span>
              <h3>Idea Generation</h3>
            </div>
            <div>
              <p>Brainstorming for project ideas.</p>
            </div>
          </div>
          <div className="plan-box">
            <div>
              <span>Step 02</span>
              <h3>Plan & Design</h3>
            </div>
            <div>
              <p>Strategizing and designing project.</p>
            </div>
          </div>
          <div className="plan-box">
            <div>
              <span>Step 03</span>
              <h3>Project Testing</h3>
            </div>
            <div>
              <p>Testing project functionality.</p>
            </div>
          </div>
          <div className="plan-box">
            <div>
              <span>Step 04</span>
              <h3>Got Results</h3>
            </div>
            <div>
              <p>Reviewing project outcomes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
