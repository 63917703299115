import React from 'react';
import './WebDevelopmentServicesDetails.css'; 
import { MdCheck } from "react-icons/md";
import ServiceImage from "../img/service-page-about.jpg"
import laef from "../img/leaf.png"
import { HiArrowSmallRight } from "react-icons/hi2";
import Plan from '../components/Plan/Plan';
import Choose from '../components/Choose/Choose';
import Faq from '../components/Faq/Faq';

const DigitalMarketing = () => {

    const faqQuestions = [
        {
          question: "What is digital marketing?",
          answer: "Digital marketing encompasses all online marketing efforts aimed at promoting products or services using digital channels such as search engines, social media, email, and websites."
        },
        {
          question: "Why is digital marketing important?",
          answer: "Digital marketing allows businesses to reach a larger audience, target specific demographics, measure campaign performance, and achieve better return on investment compared to traditional marketing methods."
        },
        {
          question: "What digital marketing services do you offer?",
          answer: "We offer a range of digital marketing services including search engine optimization (SEO), social media marketing, pay-per-click (PPC) advertising, email marketing, content marketing, and more."
        }
        // Add more questions as needed
      ];

    return (
        <div className="services-details-container">
            <div className="services-details-content">
               <div className='services-details-header'>
                    <span>Digital Marketing</span>
                    <img src={laef} alt="leaf" />
               </div>
               <div className='services-details-main-content'>
                    <div className='services-details-main-content-image'>
                        <img src={ServiceImage} alt="Service About" />
                    </div>
                    <div className='services-details-main-content-box'>
                        <div>
                            <span> <HiArrowSmallRight />What We Do</span>
                            <h1>Effective Digital Marketing Solutions</h1>
                        </div>
                        <div>
                            <p>Maximize your online presence and drive business growth with our comprehensive digital marketing services. From SEO to social media management, we have the expertise to help your business succeed in the digital world.</p>
                            <ul>
                                <li><span><MdCheck /></span>Search Engine Optimization (SEO)</li>
                                <li><span><MdCheck /></span>Social Media Marketing</li>
                                <li><span><MdCheck /></span>Pay-Per-Click (PPC) Advertising</li>
                                <li><span><MdCheck /></span>Email Marketing</li>
                                <li><span><MdCheck /></span>Content Marketing</li>
                                {/* Add more services as needed */}
                            </ul>
                        </div>
                    </div>
               </div>

                 <Plan/>
                 <Choose/>
                 <Faq faqQuestions={faqQuestions}/>
               
            </div>
        </div>
    );
};

export default DigitalMarketing;
