import React from "react";
import "./Homepage.css";
import Baner from "../../img/add-banner.png";
import { FaHtml5, FaCss3, FaJs, FaReact, FaNodeJs, FaPython,FaBootstrap,FaAngular,FaPhp } from 'react-icons/fa';
import { HiArrowLongRight } from "react-icons/hi2";
import AboutImageShape from "../../img/about-image-shape.png"
import Services from "../Services/Services";
import { MdOutlineArrowOutward } from "react-icons/md";
import project from "../../img/project-timeline-two1.jpg"
import ProfessionalsTeam from "../../components/ProfessionalsTeam";
import Testimonial from "../../components/Testimonial";
import Blog1 from "../../img/Blog1.png"
import Blog2 from "../../img/Blog2.jpg"
import Blog3 from "../../img/Blog3.jpg"
import Work1 from "../../img/work1.png"


const Homepage = () => {
  return (
    <>
    <div className="main-container">
      <div className="hero-section">
        <div className="container">
          <div className="hero-inner">
            <div className="hero-content">
              <h1 className="hero-title">Welcome to Dream Viewer</h1>
              <p className="hero-description">
                Dream Viewer is a leading software development agency dedicated
                to crafting innovative digital solutions for businesses of all
                sizes.
              </p>
              <a href="#services" className="btn btn-primary">
                Explore Our Services
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="hero-second">
      <div className="hero-second-first">
        <div className="image-container">
          <img src={Baner} alt="Banner" className="banner-image" />
        </div>
        <div className="text-container">
          <span className="project-count">150+</span>
          <h6 className="project-description">Projects Completed</h6>
          <p className="project-summary">Accomplished over 150 projects, showcasing diverse skills, expertise, and dedication to delivering quality results across various domains.</p>
        </div>
      </div>
        </div>

        <div className="skills">
      <h2>Here Are Numerous Topics That Will Enhance Your Skills</h2>
      <ul>
        <li><FaHtml5 /> HTML5</li>
        <li><FaCss3 /> CSS3</li>
        <li><FaJs /> JavaScript</li>
        <li><FaReact /> React.js</li>
        <li><FaNodeJs /> Node.js</li>
        <li><FaPython /> Python</li>
        <li><FaBootstrap /> Bootstrap</li>
        <li><FaAngular /> Angular</li>
        <li><FaPhp /> Php</li>
        
      </ul>
       </div>


        <div className="about-section">
          <div className="about-first">
            <div className="section-title mb-40">
              <span className="sub-title mb-15"><HiArrowLongRight /> About Company</span>
              <h1>We’re Award-Winning Web Development Agency</h1>
            </div>
            <div className="text-left-border mt-60 mb-65">
              <p>Sed ut perspiciatis unde omnis iste natus error sit voluptat emey accusantium doloremque laudantium totam aperiam eaque quabillo inventore veritatisey quasi architecto beatae dictasunt</p>
            </div>
              <div className="about-counter">
                <div className="about-couter-container">
                  <div>
                    <h1>100%</h1>
                    <p>Clients Satisfactions</p>
                  </div>
                  <div>
                    <h1>97%</h1>
                    <p>Success Rating</p>
                  </div>
                  <div>
                    <h1>70%</h1>
                    <p>Project Complete</p>
                  </div>
                </div>
              </div>
          </div>
          <div  className="about-second">
              <div className="image-border-shape">
                <img src={AboutImageShape} alt="about-image-shape" />
                <div className="bottom-border"></div>
              </div>
          </div>
        </div>

        <div>
          <Services/>
        </div>

        <div className="recent-work">
            <div className="recent-work-conatiner">
                <div className="react-work-headline">
                    <span>Recent Works Gallery</span>
                    <h2>Lat’s Look Our Recent Project Gallery</h2>
                </div>
                <div className="project-work-container">
                    <div className="project-work-box">
                        <span>01</span>
                        <h3>Bricks-The Gaming App</h3>
                        <div className="project-image">
                          <img src={project} alt="" />
                        </div>
                        <span><MdOutlineArrowOutward /></span>
                    </div>
                    <div className="project-work-box">
                        <span>02</span>
                        <h3>Just Afghans-Dating App</h3>
                        <div className="project-image">
                          <img src={project} alt="" />
                        </div>
                        <span><MdOutlineArrowOutward /></span>
                    </div>
                    <div className="project-work-box">
                        <span>03</span>
                        <h3>Blockchain</h3>
                        <div className="project-image">
                          <img src={project} alt="" />
                        </div>
                        <span><MdOutlineArrowOutward /></span>
                    </div>
                    <div className="project-work-box">
                        <span>04</span>
                        <h3>livehealthify</h3>
                        <div className="project-image">
                          <img src={project} alt="" />
                        </div>
                        <span><MdOutlineArrowOutward /></span>
                    </div>
                </div>
            </div>
        </div>

        <div>
          <Testimonial/>
        </div>

        <div className="blog-section">
            <div className="blog-section-container">
                <span>Blog & News</span>
                <h2>Read Our Latest News & Blog Get Every Updates</h2>
                <div className="blog-content">
                    <div className="blog-box">
                        <div>
                          <img src={Blog1} alt="" />
                          <h4><a href="/">The Importance of Custom Development for Your Business</a></h4>
                        </div>
                        <div>
                          <p>Published on: 12-04-2023</p>
                        </div>
                    </div>
                    <div className="blog-box">
                        <div>
                          <img src={Blog2} alt="" />
                          <h4><a href="/">Revolutionizing Trust: The Strength and Promise of Blockchain Technology</a></h4>
                        </div>
                        <div>
                          <p>Published on: 10-04-2023</p>
                        </div>
                    </div>
                    <div className="blog-box">
                        <div>
                          <img src={Blog3} alt="" />
                          <h4><a href="/">Unleash Your Online Presence with Our Top Notch Web Services - DreamViewer Made Easy!</a></h4>
                        </div>
                        <div>
                          <p>Published on: 28-03-2023</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
    </>
  );
};

export default Homepage;
